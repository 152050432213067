//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n } from '@/i18n';
// import HomeChart from '@/modules/home/components/home-chart.vue';
// import HomeItemSummary from '@/modules/home/components/home-item-summary.vue';
// import HomeProductTable from '@/modules/home/components/home-product-table.vue';
// import HomeSparePartTable from '@/modules/home/components/home-spare-part-table.vue';
// import HomeAccessoryTable from '@/modules/home/components/home-accessory-table.vue';
// import HomeExportationItemTable from '@/modules/home/components/home-exportation-item-table.vue';

// import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-home-page',

  components: {
    // [HomeChart.name]: HomeChart,
    // [HomeItemSummary.name]: HomeItemSummary,
    // [HomeProductTable.name]: HomeProductTable,
    // [HomeSparePartTable.name]: HomeSparePartTable,
    // [HomeAccessoryTable.name]: HomeAccessoryTable,
    // [HomeExportationItemTable.name]: HomeExportationItemTable,
  },

  created() {
    // if (this.isMobile) {
    //   this.collapseMenu();
    // }
  },

  data() {
    return {
      filterMonth: 0,
    }
  },

  computed: {
    // ...mapGetters({
    //   isMobile: 'layout/isMobile',
    //   is_screen_xs: 'layout/is_screen_xs',
    //   is_screen_sm: 'layout/is_screen_sm',
    // })
  },

  methods: {
    // ...mapActions({
    //   collapseMenu: 'layout/collapseMenu',
    // }),
    // i18n(code) {
    //   return i18n(code)
    // },
    // setFilterMonth(month){
    //   this.filterMonth = month
    // }
  }
};
